html,
body {
  background-color: #eaeaea !important;
  color: #555555 !important;
}

.mandatory::after {
  color: #dd6969;
}

.vertical-menu-seperator {
  border-color: #d8d8d8 !important;
}

a.text-link {
  color: #42c1d2 !important;
}

::selection {
  background: #f37f80 !important;
  color: #ffffff !important;
}

::-moz-selection {
  background: #f37f80 !important;
  color: #ffffff !important;
}

.k-autocomplete .k-input,
.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input,
.k-textbox,
.k-textarea,
.k-input.k-textbox {
  color: #555555 !important;
}

.k-grid tbody > .k-alt {
  background-color: #f5f5f5 !important;
}

.k-tabstrip-items .k-item.k-state-active::after {
  border-color: #104260 !important;
}

button.rounded-btn {
  background-color: #42c1d2 !important;
  color: #ffffff !important;
}

.k-window-content .k-button .k-primary {
  background: #42c1d2 !important;
}

button.rounded-btn.primary {
  background-color: #104260 !important;
}

button.rounded-btn.remove {
  background-color: #dd6969 !important;
}

.k-panelbar > .k-item {
  background-color: #104260 !important;
}

.k-panelbar > .k-item > .k-link {
  color: #ffffff !important;
}

.k-panelbar > .k-item > .k-link:hover {
  background-color: #ffa300 !important;
}

.k-panelbar > .k-item > .k-link.k-state-focused {
  background-color: #104260 !important;
}

.k-panelbar > .k-state-expanded > .k-link {
  background-color: #ffa300 !important;
}

.k-panelbar > .k-state-expanded > .k-link.k-state-selected {
  background-color: #ffa300 !important;
}

.k-textbox:hover,
.k-textbox.k-state-hover,
.k-textarea:hover,
.k-textarea.k-state-hover,
.k-input.k-textbox:hover,
.k-input.k-textbox.k-state-hover {
  border-color: #42c1d2 !important;
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textarea:focus,
.k-textarea.k-state-focus,
.k-input.k-textbox:focus,
.k-input.k-textbox.k-state-focus {
  border-color: #42c1d2 !important;
}

.k-autocomplete.k-state-focused,
.k-state-focused > .k-autocomplete,
.k-dateinput-wrap.k-state-focused,
.k-state-focused > .k-dateinput-wrap,
.k-dropdown-wrap.k-state-focused,
.k-state-focused > .k-dropdown-wrap,
.k-picker-wrap.k-state-focused,
.k-state-focused > .k-picker-wrap,
.k-multiselect-wrap.k-state-focused,
.k-state-focused > .k-multiselect-wrap,
.k-numeric-wrap.k-state-focused,
.k-state-focused > .k-numeric-wrap {
  border-color: #42c1d2 !important;
}

.k-button.k-primary,
.k-slider .k-draghandle {
  border-color: #42c1d2 !important;
  background-color: #42c1d2 !important;
}

.k-button.k-outline.k-primary {
  color: #42c1d2 !important;
}

.k-button.modal-primary {
  background-color: #42c1d2 !important;
}

.k-radio:checked + .k-radio-label::before {
  border-color: #42c1d2 !important;
}

.k-radio-label::after {
  background-color: #42c1d2 !important;
}

.k-radio:checked + .k-radio-label::after {
  background-color: #42c1d2 !important;
}

.k-checkbox:checked + .k-checkbox-label::before {
  border-color: #42c1d2 !important;
  background-color: #42c1d2 !important;
}

.k-checkbox:checked + .k-checkbox-label::after,
.k-checkbox:indeterminate + .k-checkbox-label::after {
  border-color: #42c1d2 !important;
  background-color: #42c1d2 !important;
}

.k-autocomplete:hover,
.k-autocomplete.k-state-hover,
.k-dateinput-wrap:hover,
.k-dateinput-wrap.k-state-hover,
.k-dropdown-wrap:hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap:hover,
.k-picker-wrap.k-state-hover,
.k-multiselect-wrap:hover,
.k-multiselect-wrap.k-state-hover,
.k-numeric-wrap:hover,
.k-numeric-wrap.k-state-hover {
  border-color: #42c1d2 !important;
}

.k-textarea:hover {
  border-color: #42c1d2 !important;
}

.k-widget.k-state-invalid > .k-dateinput-wrap,
.k-widget.k-state-invalid > .k-dropdown-wrap,
.k-widget.k-state-invalid > .k-picker-wrap,
.k-widget.k-state-invalid > .k-multiselect-wrap,
.k-widget.k-state-invalid > .k-numeric-wrap,
.ng-invalid.ng-touched > .k-dateinput-wrap,
.ng-invalid.ng-touched > .k-dropdown-wrap,
.ng-invalid.ng-touched > .k-picker-wrap,
.ng-invalid.ng-touched > .k-multiselect-wrap,
.ng-invalid.ng-touched > .k-numeric-wrap,
.ng-invalid.ng-dirty > .k-dateinput-wrap,
.ng-invalid.ng-dirty > .k-dropdown-wrap,
.ng-invalid.ng-dirty > .k-picker-wrap,
.ng-invalid.ng-dirty > .k-multiselect-wrap,
.ng-invalid.ng-dirty > .k-numeric-wrap {
  border-color: #dd6969 !important;
}

.k-calendar .k-state-selected .k-link {
  border-color: #42c1d2 !important;
  background-color: #42c1d2 !important;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #f37f80 !important;
}

.k-calendar .k-calendar-navigation li:hover {
  color: #f37f80 !important;
}

.k-list-optionlabel.k-state-selected {
  color: #0e4261 !important;
}

.k-pager-numbers .k-state-selected {
  background-color: #0e4261 !important;
}

.k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  color: #ffffff !important;
}

.k-grid table tr:hover,
.k-grid table tr.k-state-hover,
.k-grid table td.k-state-focused,
.k-grid table th.k-state-focused {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected,
.k-list-container .k-button:active,
.k-list-container .k-button.k-state-active,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected,
.k-spreadsheet-popup .k-button:active,
.k-spreadsheet-popup .k-button.k-state-active,
.k-spreadsheet-popup .k-button.k-state-selected,
.k-menu-group .k-item.k-state-selected,
.k-menu.k-context-menu .k-item.k-state-selected {
  color: #dd6969 !important;
}

.k-button.k-primary a {
  color: #ffffff !important;
}

.k-grid
  th
  .k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.k-dialog-wrapper .k-dialog-buttongroup .k-button {
  background-color: #0e4261 !important;
  color: #ffffff !important;
}

.k-dialog-wrapper .k-dialog-buttongroup .k-button + .k-button {
  border-left-color: rgba(255, 255, 255, 0.4) !important;
}

.k-dialog-wrapper .k-dialog-buttongroup .k-button.modal-primary {
  background-color: #42c1d2 !important;
}

.k-textbox:disabled,
.k-textarea:disabled,
.k-input.k-textbox:disabled,
.k-state-disabled > .k-autocomplete,
.k-autocomplete.k-state-disabled,
.k-state-disabled > .k-dateinput-wrap,
.k-dateinput-wrap.k-state-disabled,
.k-state-disabled > .k-dropdown-wrap,
.k-dropdown-wrap.k-state-disabled,
.k-state-disabled > .k-picker-wrap,
.k-picker-wrap.k-state-disabled,
.k-state-disabled > .k-multiselect-wrap,
.k-multiselect-wrap.k-state-disabled,
.k-state-disabled > .k-numeric-wrap,
.k-numeric-wrap.k-state-disabled {
  background-color: #f2f2f2 !important;
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: rgba(255, 255, 255, 0.75) !important;
}

.k-content.k-window-content .k-button.k-primary {
  border-color: #42c1d2 !important;
  background-color: #42c1d2 !important;
  color: #fff !important;
}

.sidenav-wrapper .arrow {
  color: #fff;
  background: #ffa300;
}

.sidenav {
  background-image: linear-gradient(to bottom, #175377, #134868, #103d59, #0c334a, #09293c);
}

.sidenav .main-menu .k-menu-item {
  color: #ffffff !important;
  border-right-color: rgba(201, 76, 76, 0);
}

.sidenav .main-menu .k-menu-item:hover {
  background-color: #ffffff;
  color: #0e4261 !important;
  border-color: #ffa300;
}

.sidenav .main-menu .k-menu-item[aria-expanded='true'] {
  background-color: #ffffff;
  color: #104260 !important;
  border-color: #ffa300;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  background-color: #ffa300 !important;
}

.main-card {
  background-color: #ffffff;
}

.main-seperator {
  border-bottom-color: #ffa300 !important;
}

.sub-chart-section + .sub-chart-section {
  border-left-color: #d8d8d8 !important;
}

.parent-phase {
  border-left-color: #ffa300;
}

.k-window-content .parent-phase .remove {
  color: #000000 !important;
}

.list-area .item-wrap {
  background-color: #f5f5f5;
}

.list-area .item-wrap:hover {
  background-color: #0e4261;
  color: #ffffff !important;
}

.item-send button,
.item-attach button {
  color: #555555;
}

.dashboard-middle .main-card {
  background-color: #9b9b9b;
}

.dashboard-middle .main-card .main-heading {
  color: #ffffff;
}

.dashboard-middle button {
  background-color: #9b9b9b;
}

.panelbar-content {
  border-color: #d8d8d8;
}

div.rounded-link {
  background-color: #0e4261;
  color: #ffffff;
}

.loader {
  background-color: rgba(255, 255, 255, 0.88);
}

.add-level .add-level-wrap {
  background-color: #f5f5f5;
}

.inline-error {
  color: #dd6969;
}

.k-grid table tr.summery-header-style {
  background-color: #0e4261 !important;
  color: #ffffff !important;
}

.k-grid table tr.summery-header-style:hover {
  background-color: #0e4261 !important;
  color: #ffffff !important;
}

.verison-number {
  color: #ffffff;
}

.resource-mix-grid.k-grid tbody > .k-alt {
  background-color: #ffffff !important;
}

.summary-grid.k-grid tbody > .k-alt {
  background-color: #ffffff !important;
}

.resource-mix-grid.k-grid tbody > .k-alt {
  background-color: #ffffff !important;
}

.file-upload-list li a span {
  color: #42c1d2;
}

.file-upload-list li:hover {
  background-color: #42c1d2;
  color: #ffffff;
}

.file-upload-list li:hover a span {
  color: #ffffff;
}

.file-upload-list .file-view {
  border-color: #42c1d2 !important;
}

.custom-file-input::before {
  background: #ffffff;
  border: 1px solid #42c1d2;
  text-shadow: 1px 1px #fff;
  color: #42c1d2;
}

.custom-file-input:hover::before {
  border-color: #ffa300;
  color: #ffa300;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.audit-trail-grid .k-master-row td {
  background-color: #ffffff !important;
}

.audit-trail-grid .k-master-row.k-alt td {
  background-color: #f5f5f5 !important;
}

.approver-type-grid.k-grid .k-grid-content .k-master-row td {
  background-color: #ffffff !important;
}

.approver-type-grid.k-grid .k-grid-content .k-master-row.k-alt td {
  background-color: #f5f5f5 !important;
}

.search-result-grid .k-master-row td {
  background-color: #ffffff !important;
}

.search-result-grid .k-master-row.k-alt td {
  background-color: #f5f5f5 !important;
}

.k-master-row {
  background-color: #ffffff !important;
}

.k-grid table tr:hover {
  background-color: #ededed !important;
}

.top-bar {
  background-color: #f5f5f5;
}

.top-bar .page-title {
  color: #0e4261;
}

.top-bar .k-menu-link {
  color: #555555 !important;
}

.top-bar .k-menu-link.k-state-active {
  background-color: #0e4261;
  color: #ffffff !important;
}

.top-bar .global-search label {
  color: #0e4261;
}

.k-grid .k-grid-header {
  border-color: #0e4261 !important;
  background-color: #0e4261;
  color: #ffffff !important;
}

.k-grid .k-grid-header .k-header > .k-link {
  color: #ffffff !important;
}

.k-grid .k-grid-header tr:hover {
  background-color: #0e4261 !important;
}

.k-grid .k-grid-header tr:hover .k-header > .k-link {
  color: #ffffff !important;
}

.k-grid .k-grid-header .k-checkbox-label:before {
  border-color: #ffffff !important;
}

.panelbar-wrapper.modified .k-animation-container {
  background-color: #f0f0f0;
}

.panelbar-wrapper.modified .k-panelbar .k-group > .k-item > .k-link.k-state-selected {
  color: rgba(0, 0, 0, 0.87);
}

.text-red {
  color: red;
}

.border-red {
  border-color: red !important;
}

.global-search-content .k-grid {
  background-color: #0e4261;
}

.global-search-content .k-grid-header {
  border-bottom-color: #ffffff !important;
}

.global-search-content a:not([href]):not([tabindex]) {
  color: #ffffff !important;
}

.global-search-content label {
  color: #ffffff !important;
}

.global-search-content .k-radio-label {
  color: #000 !important;
}

.global-search-content .k-pager-wrap .k-pager-info {
  color: #ffffff !important;
}

.global-search-content .k-pager-wrap .k-link {
  color: #ffffff !important;
}

.global-search-content .k-grid table tr.k-alt:hover {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.global-search-content .k-pager-numbers .k-state-selected {
  background-color: #ffa300 !important;
}

.global-search-content .close-btn {
  background-color: #0e4261;
}

.global-search-content .close-btn button {
  background-color: #ffa300;
  color: #ffffff;
}

.global-search-content .k-grid-norecords {
  color: #ffffff;
}

.global-search-content .k-grid-norecords:hover {
  color: #ffffff;
}

.maintenance {
  background-color: #ffffff;
  border-bottom-color: #42c1d2;
}

.internet-error {
  background-color: #f37f80;
  color: #ffffff;
}

.searchbar .k-header:hover {
  border-color: #42c1d2 !important;
}

.file-upload-list .doc-list {
  border-color: #42c1d2 !important;
}

.badge-danger .k-input {
  color: #ffffff !important;
}

.phase-button .k-button.project-phase {
  color: #ffffff !important;
  background: #42c1d2 !important;
}

.global-search-content .k-master-row {
  background-color: #0e4261 !important;
}

.global-search-content .k-grid tbody tr:hover {
  background: #0e4261 !important;
}

.quill-toolbar .ql-toolbar.ql-snow + .ql-container.ql-snow {
  background: #f2f2f2 !important;
}

.badge-primary .k-input {
  color: #ffffff !important;
}

.badge-primary .k-textbox {
  color: #ffffff !important;
}

.badge-danger .k-textbox {
  color: #ffffff !important;
}

.badge-danger .k-dropdown-wrap {
  background: #dc3535 !important;
}

.badge-warning .k-dropdown-wrap {
  background: #ffc107 !important;
}

.badge-primary .k-dropdown-wrap {
  background: #007bff !important;
}

.badge-danger .k-textbox {
  background: #dc3535 !important;
}

.k-detail-row .k-alt .k-grid-content tr:last-child td,
.k-detail-row .k-grid-content tr:last-child td {
  border-bottom-color: #8d8d8d !important;
}

.k-detail-row .k-detail-cell,
.k-master-row.k-alt .k-detail-cell {
  border-bottom-color: #8d8d8d !important;
}

.profile-pic {
  background-color: #b4b4b4;
}

.k-pager-wrap .k-link,
.k-pager-wrap .k-pager-nav {
  background: none !important;
  color: #0e4261 !important;
  border-radius: 20px;
  border: 0;
}

.k-pager-wrap .k-link.k-state-disabled {
  opacity: 0.3;
}

.k-pager-numbers .k-state-selected,
.k-pager-numbers .k-state-selected:hover {
  border-color: #0e4261 !important;
  background-color: #0e4261 !important ;
  border-radius: 20px;
  color: #ffffff !important;
}

/* Mobile Menu */

.mb-menu {
  background: #104260;
}

.mb-close-btn span {
  color: #ffffff;
}
div.rounded-link-green {
  background: #007d53;
  color: #ffffff;
}
