@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap');


*{
    font-family: 'Montserrat', sans-serif;
}


.user-info .k-menu.k-menu-horizontal:not(.k-context-menu) {
    padding: 0 !important;
    background: transparent;
}

.sidenav .main-menu .k-menu:not(.k-context-menu){
    background: transparent;
}

.k-panelbar.accordion-panel .accordion-tab .k-link.k-header {
    background: #104260;
}

.searchbar .k-header {
    border-color: #aeaeae !important;
}

.main-card .k-tabstrip .k-tabstrip-items .k-item.k-state-active.k-tab-on-top {
    font-weight: 700;
}

.main-card .k-tabstrip .k-tabstrip-items .k-item.k-state-default {
    color: #555555 !important;
}

.k-combobox .k-select, .k-datepicker .k-select {
    border-color: #104260 !important;
    color: #ffffff !important;
    background-color: #104260 !important;
}

.primary-btn {
    border-color: #104260 !important;
    background-color: #104260 !important;
    color: #ffffff !important;
    font-weight: 700 !important;
}

.primary-btn:hover {
    opacity: 0.9 !important;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.secondary-btn {
    border-color: #ffa300 !important;
    background-color: #ffa300 !important;
    color: #ffffff !important;
    font-weight: 700 !important;

}

.secondary-btn:hover {
    opacity: 0.9 !important;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}